<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Punt PS</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefRouteCompliance"
              id="datatable_reporting_puntps"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ReportingPuntPS",
  data: function() {
    return {
      visitTypes: [],
      VISIT_TYPES: {},
      filterlist_fetch_url: "reporting/puntps/getfilterlist",
      filterable_col_list: [],
      filterCriteria: {
        route: {},
        date: {},
        user: {},
        pos: {},
        address_label: "",
        brand: {},
        chain: {},
        subchain: {},
        sub2chain: {},
        channel: {},
        subchannel: {},
        sub2channel: {},
        realVisitType: {},
        posBrandPSCurrentVisit: {},
        objectiveValue: {},
        diffScoreVsLastVisit: {},
        salesObjective: {},
        salesTotalValue: {},
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,
      totaldata: 0,
      data: [],
    };
  },
  watch: {},
  computed: {
    col_route() {
      return {
        key: "route",
        title: this.$t("route"),
        field: "route",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["route"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`route`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["route"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "route")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: "",
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: "",
      };
    },
    col_pos() {
      return {
        key: "pos",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["address_label"]}
                  on-input={(value) =>
                    (this.filterCriteria["address_label"] = value)
                  }
                  placeholder="Search Address"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address_label")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_brand() {
      return {
        key: "brand",
        title: this.$t("brands.brands"),
        field: "brand",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.brand_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brand"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brand`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brand"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brand")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_chain() {
      return {
        key: "chain",
        title: this.$t("chain"),
        field: "chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_subchain() {
      return {
        key: "subchain",
        title: "Sub " + this.$t("chain"),
        field: "subchain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_sub2chain() {
      return {
        key: "sub2chain",
        title: "Sub-Sub " + this.$t("chain"),
        field: "sub2chain",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2chain_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2chain"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2chain`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2chain"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2chain")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_channel() {
      return {
        key: "channel",
        title: this.$t("channel"),
        field: "channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_subchannel() {
      return {
        key: "subchannel",
        title: "Sub " + this.$t("channel"),
        field: "subchannel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.subchannel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subchannel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subchannel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subchannel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subchannel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_sub2channel() {
      return {
        key: "sub2channel",
        title: "Sub-Sub " + this.$t("channel"),
        field: "sub2channel",
        align: "left",
        width: 200,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row?.sub2channel_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["sub2channel"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`sub2channel`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["sub2channel"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "sub2channel")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_posBrandPSCurrentVisit() {
      return {
        key: "posBrandPSCurrentVisit",
        title: `punt total PS`,
        field: "posBrandPSCurrentVisit",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={
                    this.filterCriteria["posBrandPSCurrentVisit"]["from"]
                  }
                  value={this.filterCriteria["posBrandPSCurrentVisit"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["posBrandPSCurrentVisit"]["to"]}
                  value={this.filterCriteria["posBrandPSCurrentVisit"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "posBrandPSCurrentVisit")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_objectiveValue() {
      return {
        key: "objectiveValue",
        title: `objectivo PS`,
        field: "objectiveValue",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["objectiveValue"]["from"]}
                  value={this.filterCriteria["objectiveValue"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["objectiveValue"]["to"]}
                  value={this.filterCriteria["objectiveValue"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "objectiveValue")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_diffScoreVsLastVisit() {
      return {
        key: "diffScoreVsLastVisit",
        title: `vs Ult visits PS`,
        field: "diffScoreVsLastVisit",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{this.formatNumberForPlus(row.diffScoreVsLastVisit)}</span>
          );
        },
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={
        //             this.filterCriteria["diffScoreVsLastVisit"]["from"]
        //           }
        //           value={
        //             this.filterCriteria["diffScoreVsLastVisit"]["from"]
        //           }
        //           placeholder="From - "
        //         />
        //         <br />
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={
        //             this.filterCriteria["diffScoreVsLastVisit"]["to"]
        //           }
        //           value={this.filterCriteria["diffScoreVsLastVisit"]["to"]}
        //           placeholder="To - "
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() =>
        //               this.searchCancel(closeFn, "diffScoreVsLastVisit")
        //             }
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_realVisitType() {
      return {
        key: "realVisitType",
        title: `${this.$t("visit_type")}`,
        field: "realVisitType",
        align: "left",
        sortBy: "",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.realVisitType_label}</span>;
        },
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <filterableCheckboxesOthers
        //           v-model={this.filterCriteria["realVisitType"]}
        //           dataList={this.visitTypes}
        //           filterCriteria={this.filterCriteria["realVisitType"]}
        //           itemvalue="value"
        //           itemlabel="label"
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "realVisitType")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_salesObjective() {
      return {
        key: "salesObjective",
        title: `objectivo Ventas`,
        field: "salesObjective",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesObjective"]["from"]}
                  value={this.filterCriteria["salesObjective"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesObjective"]["to"]}
                  value={this.filterCriteria["salesObjective"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "salesObjective")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    col_salesTotalValue() {
      return {
        key: "salesTotalValue",
        title: `Ventas`,
        field: "salesTotalValue",
        align: "left",
        sortBy: "",
        width: 180,
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesTotalValue"]["from"]}
                  value={this.filterCriteria["salesTotalValue"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["salesTotalValue"]["to"]}
                  value={this.filterCriteria["salesTotalValue"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "salesTotalValue")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          },
        },
      };
    },
    columns() {
      return [
        this.col_route,
        this.col_date,
        this.col_userId,
        this.col_user,
        this.col_posId,
        this.col_pos,
        this.col_address_label,
        this.col_realVisitType,
        this.col_brand,
        this.col_chain,
        this.col_subchain,
        this.col_sub2chain,
        this.col_channel,
        this.col_subchannel,
        this.col_sub2channel,
        this.col_posBrandPSCurrentVisit,
        this.col_objectiveValue,
        this.col_diffScoreVsLastVisit,
        this.col_salesTotalValue,
        this.col_salesObjective,
      ];
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        [
          "posBrandPSCurrentVisit",
          "objectiveValue",
          "diffScoreVsLastVisit",
          "salesTotalValue",
          "salesObjective",
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_reporting_puntps"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (
            [
              "posBrandPSCurrentVisit",
              "objectiveValue",
              "diffScoreVsLastVisit",
              "salesTotalValue",
              "salesObjective",
            ].includes(key)
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value,
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable_reporting_puntps .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "reporting/puntps";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.visitTypes = resp.visitTypes;
        this.approval_status = resp.approval_status;
        this.VISIT_TYPES = resp.VISIT_TYPES;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "reporting/puntps/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    init() {
      this.filterable_col_list = Object.keys(this.filterCriteria);
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
